import { Component, OnInit } from '@angular/core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook , faAmazon } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  faBell = faBell;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  faAmazon = faAmazon;
  constructor() { }

  ngOnInit() {
  }

}
