import { Component, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  @Output() part : EventEmitter<string> = new EventEmitter<string>(); 

  constructor() { }

  ngOnInit() {
  }

  scrollToContact() {
    let componentTargeted = "contact";
    console.log("event recu : ", componentTargeted)
    this.part.emit(componentTargeted); 
}

  scrollToDetails() {
    let componentTargeted = "details";
    console.log("event recu : ", componentTargeted)
    this.part.emit(componentTargeted); 
  }

}
