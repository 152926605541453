import { Component, ElementRef } from '@angular/core';
import { faFilm } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private myElement: ElementRef) { }

  title = 'sarva-yoga';
  faFilm = faFilm;

  scrollToEvent(event) {

    console.log("event recu from pricing : ", event)
    var el : any;
    if(event === "contact")
    {
      el = this.myElement.nativeElement.querySelector("app-contact");
    }
    else
    {
      el = this.myElement.nativeElement.querySelector("app-services-details");
    }
    
    el.scrollIntoView();
}
}
